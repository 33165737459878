import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as History from 'history';

import { useAuth } from "./ProvideAuth";
import LoadingView from "./LoadingView";
import { UserModel } from "../Modules/Auth/UsersModel";

interface IAuthRoute {
  path: string,
  authCheck: (user: UserModel | null) => boolean,
  checkFailRedirect: string | ((user: UserModel | null) => string),
  children: JSX.Element,
}
export default function AuthRoute({ path, authCheck, checkFailRedirect, children, ...rest }: IAuthRoute): JSX.Element {
  const auth = useAuth();
  if (auth.user === undefined) {
    return <LoadingView />;
  }

  const authCheckFn = (): boolean => {
    if (auth.user === undefined) {
      throw "unreachable";
    }

    const result = authCheck(auth.user);
    console.log("AuthRoute:AuthCheck:" + path + " result:" + result);
    return result;
  };
  const checkFailRedirectFn = (): string => {
    if (auth.user === undefined) {
      throw "unreachable";
    }
    
    let result: string | null = null;
    if (typeof checkFailRedirect === 'function') {
      result = checkFailRedirect(auth.user);
    } else {
      result = checkFailRedirect;
    }
    console.log("AuthRoute:Redirect:" + path + " result:" + result);
    return result;
  };

  const renderFn = ({ location }: { location: History.Location<History.LocationState>}) => {
    if (auth.user === undefined) {
      return <LoadingView />;
    } else if (authCheckFn()) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: checkFailRedirectFn()
          }}
          from={location.pathname}
        />
      );
    }
  };

  return (
    <Route
      {...rest}
      path={path}
      render={renderFn}
    />
  );
}