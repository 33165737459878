import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import BaseModel from './BaseModel';
import { BaseValueType, IValueType } from './FormBuilder';

interface IFormTextInput {
  onChange?: (value: string) => void,
  value?: string,
  disabled?: boolean
}
export function TextInput(options: IFormTextInput): JSX.Element {
  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (options.onChange !== undefined && (options.disabled === undefined || options.disabled === false)) {
      options.onChange(e.currentTarget.value);
    }
  };

  return (
    <Form.Control
      type="text"
      value={options.value}
      disabled={options.disabled}
      autoComplete="false"
      autoCorrect="false"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e) => onChangeFn(e as any)} />
  );
}

interface IFormText {
  id: string,
  displayName: string,
  onChange?: (value: string) => void,
  value?: string,
  disabled?: boolean
}
export default function FormText({ onChange, id, displayName, value, disabled }: IFormText): JSX.Element {
  return (
    <Form.Group controlId={id}>
      <Form.Label>{displayName}</Form.Label>
      <TextInput
        value={value}
        disabled={disabled}
        onChange={onChange} />
    </Form.Group>
  );
}

export class StringValueType extends BaseValueType implements IValueType {
  #value: string;
  #onFinalize: ((model: BaseModel, value: string) => void) | null;
  #valueSetter: Dispatch<SetStateAction<string>>;

  constructor(
    displayName: string,
    id: string,
    [value, valueSetter]: [string, Dispatch<SetStateAction<string>>],
    onFinalize: ((model: BaseModel, value: string) => void) | null) {
    super(displayName, id);
    this.#value = value;
    this.#onFinalize = onFinalize;
    this.#valueSetter = onFinalize === null ? () => {/*no-op*/} : valueSetter;
  }

  finalize(model: BaseModel): void {
    if (this.#onFinalize !== null) {
      this.#onFinalize(model, this.#value);
    }
  }

  getElement(key: string): JSX.Element {
    return (
      <FormText
        key={key}
        displayName={this.displayName}
        id={this.id}
        value={this.#value}
        disabled={this.#onFinalize === null}
        onChange={(value: string) => this.#valueSetter(value)} />
    );
  }
}