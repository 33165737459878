import firebase from "firebase/app";

interface IMetadataPayload {
  customMetadata: { name: string }
}

export class StorageMetadata {
  #metadata: IMetadataPayload;

  constructor(metadata?: IMetadataPayload) {
    if (metadata === undefined) {
      this.#metadata = {
        customMetadata: {
          name: ""
        }
      };
    } else {
      this.#metadata = metadata;
    }
  }

  getName(): string | undefined {
    return this.#metadata.customMetadata?.name;
  }

  setName(name: string): void {
    this.#metadata.customMetadata.name = name;
  }

  getMetadata(): IMetadataPayload {
    return this.#metadata;
  }
}

export class StorageRef {
  #ref: firebase.storage.Reference;

  constructor(ref: firebase.storage.Reference) {
    this.#ref = ref;
  }

  getDownloadURL(): Promise<string> {
    return this.#ref.getDownloadURL();
  }

  async getMetadata(): Promise<StorageMetadata> {
    const metadata = await this.#ref.getMetadata();
    return new StorageMetadata(metadata);
  }

  put(file: File, metadata: StorageMetadata): Promise<void> {
    return new Promise((resolve, reject) => {
      const uploadTask = this.#ref.put(file, metadata.getMetadata());
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        null,
        (error: firebase.storage.FirebaseStorageError) => {
          console.error(error);
          reject(error);
        },
        resolve);
    });
  }
}

export default class FirebaseStorage {
  getRef(path: string): StorageRef {
    return new StorageRef(firebase.storage().ref().child(path));
  }
}