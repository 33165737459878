import React, { useState, useEffect, useContext, createContext } from "react";

import { UserModel, UsersModel } from "../Modules/Auth/UsersModel";
import GetAuth from "../Firebase/FirebaseConfig";

const authContext = createContext<AuthContext>({
  user: undefined
});

interface AuthContext {
  user: UserModel | null | undefined
}

// Provider component that wraps your app and makes auth object 
// available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: JSX.Element}): JSX.Element {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): AuthContext => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<UserModel | null | undefined>(undefined);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = GetAuth().onAuthStateChanged(userAuth => {
      console.log("authStateChanged");
      console.log(userAuth);
      if (userAuth) {
        const processUser = async () => {
          try {
            if (userAuth.email === null) {
              throw "email missing";
            }
            if (userAuth.displayName === null || userAuth.email === null) {
              throw "displayName or email are null";
            }

            const usersModel = new UsersModel();
            let user = await usersModel.getByIdAsync(userAuth.uid) as UserModel | null;
            if (user === null) {
              user = new UserModel(
                userAuth.uid, 
                userAuth.displayName,
                userAuth.email);
              await usersModel.setAsync(userAuth.uid, user);
            }

            console.log(user);
            setUser(user);
          } catch (error) {
            console.error(error);
            setUser(null);
            GetAuth().signOut();
          }
        };
        processUser();
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return {
    user: user
  };
}
