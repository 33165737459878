import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from 'react-router-dom';

import { HelmetTitle } from "../../Utils/HelmetTitle";
import { OrganizationModel, OrganizationsModel } from "./OrganizationsModel";
import { useAuth } from "../../Initializer/ProvideAuth";


function OrganizationItem({ item }: { item: OrganizationModel }) {
  const { path } = useRouteMatch();

  return (
    <li>
      <Link to={path + "/" + item.name}>{item.friendlyName}</Link>
    </li>
  );
}


function OrganizationList({ loaded, items }: { loaded: boolean, items: OrganizationModel[]}) {
  if (!loaded) {
    return null;
  } else if (items.length === 0) {
    return (
      <div>
        No organizations
      </div>
    );
  } else {
    return (
      <ul>
        { items.map(item => <OrganizationItem key={item.name} item={item} />) }
      </ul>
    );
  }
}

export default function OrganizationsView(): JSX.Element {
  const [items, setItems] = useState<OrganizationModel[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [organizationsModel] = useState(new OrganizationsModel());
  const auth = useAuth();
  const { path } = useRouteMatch();
  
  useEffect(() => {
    async function asyncEffect() {
      if (auth.user === null || auth.user === undefined) {
        throw "user missing";
      }

      const organizationList = await organizationsModel.getOrganizationsByUserAsync(auth.user);
      console.log(organizationList);
      setItems(organizationList);
      setLoaded(true);
    }
    asyncEffect();
  }, [auth.user, organizationsModel]);

  return (
    <div>
      <HelmetTitle title="Organizations" />
      <h1>Organizations</h1>
      <Link to={path + "/create"}>Create New Organization</Link>
      <OrganizationList items={items} loaded={loaded} />
    </div>
  );
}