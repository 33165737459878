import firebase from "firebase/app";
import BaseModel, { IDocumentConverter } from "./BaseModel";

export default class BaseCollection {
  protected db: firebase.firestore.Firestore;
  protected collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  #converter: IDocumentConverter;

  constructor(collectionPath: string, converter: IDocumentConverter) {
    this.db = firebase.firestore();
    this.collection = this.db.collection(collectionPath);
    this.#converter = converter;
  }

  async getAllAsync(): Promise<BaseModel[]> {
    const collectionRef = await this.collection.get();
    const items: BaseModel[] = [];
    for (const i in collectionRef.docs) {
      const doc = collectionRef.docs[i];
      console.log(this.#converter.fromFirestore(doc));
      items.push(this.#converter.fromFirestore(doc));
    }

    console.log(items);
    return items;
  }

  async getByIdAsync(id: string): Promise<BaseModel | null> {
    const doc = await this.collection.doc(id)
      .get();

    if (doc.exists) {
      return this.#converter.fromFirestore(doc);
    } else {
      return null;
    }
  }

  async addAsync(item: BaseModel): Promise<BaseModel> {
    const docRef = await this.collection.add(this.#converter.toFirestore(item));
    const doc = await docRef.get();
    return this.#converter.fromFirestore(doc);
  }

  async setAsync(id: string, item: BaseModel): Promise<BaseModel> {
    await this.collection.doc(id)
      .set(this.#converter.toFirestore(item));
    return item;
  }

  async deleteAsync(id: string): Promise<void> {
    await this.collection.doc(id).delete();
  }
}