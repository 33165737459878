import React, { useState, useEffect, useContext, createContext } from "react";
import { useParams, useHistory } from 'react-router-dom';

import { OrganizationModel, OrganizationsModel } from "./OrganizationsModel";
import Loading from "../../Initializer/LoadingView";
import { MemberModel, MembersModel } from "./Members/MembersModel";
import { useAuth } from "../../Initializer/ProvideAuth";

export interface IOrganizationContext {
  organization: OrganizationModel | null,
  member: MemberModel | null,
}

const organizationContext = createContext<IOrganizationContext>({
  organization: null,
  member: null,
});

// Provider component that wraps your app and makes auth object 
// available to any child component that calls useOrganization().
export function ProvideOrganizationContext({ children }: { children: JSX.Element } ): JSX.Element {
  const orgContext = useProvideOrganizationContext();

  if (orgContext.organization !== null) {
    return (
      <organizationContext.Provider value={orgContext}>
        {children}
      </organizationContext.Provider>
    );
  } else {
    return (
      <Loading />
    );
  }
}

// Hook for child components to get the auth object and re-render when it changes.
export const useOrganizationContext = (): IOrganizationContext => {
  return useContext(organizationContext);
};

interface IOrganizationParams {
  orgName: string
}

// Provider hook that creates auth object and handles state
function useProvideOrganizationContext() {
  const [organization, setOrganization] = useState<IOrganizationContext>({
    organization: null,
    member: null,
  });
  const params = useParams<IOrganizationParams>();
  const orgName = params.orgName;
  const history = useHistory();
  const auth = useAuth();

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest organization object.
  useEffect(() => {
    async function asyncEffect() {
      const organizationsModel = new OrganizationsModel();
      const organization = await organizationsModel.getByNameAsync(orgName);
      if (organization === null || organization.id === null) {
        console.error("organization not found");
        history.replace("/notFound");
        return;
      }

      if (auth.user === null || auth.user === undefined) {
        throw "unreachable";
      }

      const membersModel = new MembersModel(organization.id);
      const member = await membersModel.getByIdAsync(auth.user.email) as MemberModel;
      if (member === null) {
        console.error("member not found");
        history.replace("/notFound");
        return;
      }

      setOrganization({
        organization: organization,
        member: member,
      });
    }
    asyncEffect();
  }, [orgName, history, auth]);

  return organization;
}