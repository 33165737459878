import firebase from "firebase/app";

import BaseCollection from "../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../Utils/BaseModel";
import FirestorePath from "../../Utils/FirestorePath";

export class UserModel extends BaseModel {
  name: string;
  email: string;

  constructor(id: string, name: string, email: string) {
    super(id);
    this.name = name;
    this.email = email;
  }
}

class UserConverter implements IDocumentConverter {
  toFirestore(user: UserModel) {
    return {
      name: user.name,
      email: user.email
    };
  }

  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = snapshot.data();
    return new UserModel(
      snapshot.id,
      data.name,
      data.email);
  }
}

export class UsersModel extends BaseCollection {
  constructor() {
    super(FirestorePath.UsersPath, new UserConverter());
  }

  async getUserByEmailAsync(email: string): Promise<UserModel | null> {
    console.log("getUserByEmailAsync");
    const querySnapshot =
      await this.collection
        .where("email", "==", email)
        .get();

    console.log(querySnapshot);

    if (querySnapshot.empty) {
      return null;
    } else {
      const userConverter = new UserConverter();
      console.log(querySnapshot.docs[0].data());
      return userConverter.fromFirestore(querySnapshot.docs[0]);
    }
  }
}