import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import BaseCollection from './BaseCollection';
import BaseModel from './BaseModel';
import DeleteButton from './DeleteButton';
import { HelmetEditTitle } from './HelmetTitle';

export interface IValueType {
  getElement(key: string): JSX.Element;
  finalize(model: BaseModel): void;
}

export class BaseValueType {
  protected displayName: string;
  protected id: string;

  constructor(
    friendlyName: string,
    name: string) {
    this.displayName = friendlyName;
    this.id = name;
  }
}

export enum UserAction {
  Add,
  Edit,
  Delete,
  Cancel
}
export type UserActionCompleted = (action: UserAction, model: BaseModel) => void;

interface IFormBuilder {
  name: string;
  friendlyName: string | null;
  values: IValueType[];
  onDone: UserActionCompleted;
  model: BaseModel;
  collection: BaseCollection;
}
export function FormBuilder(options: IFormBuilder): JSX.Element {
  const isNew = options.model.id === null || options.model.id === "";

  const onSubmit = async () => {
    options.values.forEach((value: IValueType) => {
      value.finalize(options.model);
    });

    const savedModel = options.model.id === null || options.model.id === ""
      ? await options.collection.addAsync(options.model)
      : await options.collection.setAsync(options.model.id, options.model);

    options.onDone(isNew ? UserAction.Add : UserAction.Edit, savedModel);
  };
  const onSubmitFn = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
    return false;
  };
  const onDelete = async () => {
    if (options.model.id !== null) {
      await options.collection.deleteAsync(options.model.id);
      options.onDone(UserAction.Delete, options.model);
    }
  };
  const onCancel = async() => {
    options.onDone(UserAction.Cancel, options.model);
  };

  const deleteBtn = isNew
    ? null 
    : <DeleteButton onClick={onDelete} />;
  const header = `${isNew ? "Create" : "Edit"} ${options.name}`;
  return (
    <Form
      onSubmit={onSubmitFn}>
      <HelmetEditTitle
        isNew={isNew}
        name={options.name}
        friendlyName={options.friendlyName} />
      <Row>
        <Col xs={{ span: 8 }}><h1>{header}</h1></Col>
        <Col xs={{ span: 4 }} className="right-align">{deleteBtn}</Col>
      </Row>
      {options.values.map((value: IValueType, i: number) => value.getElement("control" + i++))}
      <Button variant="primary" type="submit">Save</Button>
      <Button variant="secondary" onClick={onCancel}>Cancel</Button>
    </Form>
  );
}
