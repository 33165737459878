import React, { useState, Fragment } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

import { useAuth } from '../../Initializer/ProvideAuth';

import FormText from '../../Utils/FormText';
import { HelmetTitle } from '../../Utils/HelmetTitle';
import { MemberModel, MembersModel, MemberRoles } from './Members/MembersModel';
import { OrganizationModel, OrganizationsModel } from './OrganizationsModel';
import OrganizationUtils from './OrganizationUtils';

export default function CreateOrganizationView(): JSX.Element {
  const [friendlyName, setFriendlyName] = useState('');
  const auth = useAuth();
  const history = useHistory();
  const organizationsModel = new OrganizationsModel();
  const organizationUtils = new OrganizationUtils();

  const onNameChange = (value: string) => {
    const result = organizationUtils.validateName(value);
    if (result === true || result === 'minLength') {
      setFriendlyName(value);
    }
  };

  const onSubmit = async () => {
    if (auth.user === null || auth.user === undefined) {
      throw "unreachable";
    }

    const validationResult = organizationUtils.validateName(friendlyName);
    if (validationResult !== true) {
      console.error(validationResult);
      return;
    }

    const name = organizationUtils.cleanName(friendlyName);
    const result = await organizationUtils.validateUniqueAsync(name);
    if (result !== true) {
      console.error(result);
      return;
    }

    const newOrganization = new OrganizationModel(null, name, friendlyName);
    newOrganization.creator = auth.user.id;
    const organization = (await organizationsModel.addAsync(newOrganization)) as OrganizationModel;
    if (organization.id === null) {
      throw "invalid id";
    }

    const members = new MembersModel(organization.id);
    await members.setAsync(auth.user.email, new MemberModel(auth.user.email, MemberRoles.Admin));

    // Remove creator field as user is now a member
    await organizationsModel.setAsync(organization.id, organization);

    history.push('/org/' + organization.name);
  };

  const onSubmitFn = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
    return false;
  };

  return (
    <Fragment>
      <HelmetTitle title="Create Organization" />
      <Form onSubmit={onSubmitFn}>
        <h1>Create Organization</h1>
        <Form.Group>
          <FormText
            displayName="Name"
            id="name"
            value={friendlyName}
            onChange={onNameChange} />
        </Form.Group>
        <Button type="submit">Create</Button>
      </Form>
    </Fragment>
  );
}
