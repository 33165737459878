import React, { Fragment } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from './Initializer/ProvideAuth';
import { ProvideOrganizationContext, useOrganizationContext } from './Modules/Organizations/ProvideOrganizationContext';

function HistoryPush(path: string) {
  const history = useHistory();
  return () => history.push(path);
}

function AuthNavbar(): JSX.Element {
  const auth = useAuth();
  
  if (!auth.user) {
    return (
      <Nav.Link onClick={HistoryPush('/login')}>
        Login
      </Nav.Link>
    );
  } else {
    return (
      <NavDropdown title={auth.user.name} id="navbar-auth" alignRight>
        <NavDropdown.Item onClick={HistoryPush('/org')}>
          Organizations
        </NavDropdown.Item>
        <NavDropdown.Item onClick={HistoryPush('/logout')}>
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
}

function UnauthenticatedNavbar(): JSX.Element {
  return (
    <Navbar bg="light" expand="md">
      <Navbar.Brand>Printer</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" />
        <AuthNavbar />
      </Navbar.Collapse>
    </Navbar>
  );
}

function AuthenticatedNavbar(): JSX.Element {
  return (
    <Navbar bg="light" expand="md">
      <Navbar.Brand>Printer</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" />
        <AuthNavbar />
      </Navbar.Collapse>
    </Navbar>
  );
}

function OrganizationNavbar(): JSX.Element {
  const organizationContext = useOrganizationContext();
  const { url } = useRouteMatch();

  if (!organizationContext.organization) {
    return <div />;
  }

  return (
    <Navbar bg="light" expand="md">
      <Navbar.Brand>{organizationContext.organization.friendlyName}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={HistoryPush(url)}>Home</Nav.Link>
          <Nav.Link onClick={HistoryPush(url + '/customer')}>Customers</Nav.Link>
          <NavDropdown title="Menu" id="navbar-menu">
            <NavDropdown.Item onClick={HistoryPush(url + '/menuitem')}>
              Menu Items
            </NavDropdown.Item>
            <NavDropdown.Item onClick={HistoryPush(url + '/menucategory')}>
              Menu Categories
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={HistoryPush(url + '/member')}>Members</Nav.Link>
        </Nav>
        <AuthNavbar />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default function AppNavbar(): JSX.Element {
  const auth = useAuth();

  if (auth.user === undefined) {
    return <Fragment />;
  } else if (auth.user === null) {
    return <UnauthenticatedNavbar />;
  } else {
    return (
      <Switch>
        <Route exact path="/org/create">
          <AuthenticatedNavbar />
        </Route>
        <Route path="/org/:orgName">
          <ProvideOrganizationContext>
            <OrganizationNavbar />
          </ProvideOrganizationContext>
        </Route>
        <Route>
          <AuthenticatedNavbar />
        </Route>
      </Switch>
    );
  }
}