import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import CreateOrganizationView from './CreateOrganizationView';
import OrganizationView from './OrganizationView';
import OrganizationsView from './OrganizationsView';
import MembersView from './Members/MembersView';
import CustomersView from "./Customers/CustomersView";
import MenuItemsView from "./Menu/MenuItem/MenuItemsView";
import MenuCategoryView from "./Menu/MenuCategory/MenuCategoryView";
import MenuCategoriesView from "./Menu/MenuCategory/MenuCategoriesView";
import { ProvideOrganizationContext } from "./ProvideOrganizationContext";
import NotFoundView from "../../Initializer/NotFoundView";
import MenuItemView from "./Menu/MenuItem/MenuItemView";
import CustomerView from "./Customers/CustomerView";
import MemberView from "./Members/MemberView";

export default function OrganizationRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <CreateOrganizationView />
      </Route>
      <Route exact path={path}>
        <OrganizationsView />
      </Route>
      <Route path={`${path}/:orgName`}>
        <ProvideOrganizationContext>
          <Switch>
            <Route strict exact path={`${path}/:orgName/member`}>
              <MembersView />
            </Route>
            <Route path={`${path}/:orgName/member/:itemId?`}>
              <MemberView />
            </Route>
            <Route strict exact path={`${path}/:orgName/customer`}>
              <CustomersView />
            </Route>
            <Route path={`${path}/:orgName/customer/:itemId?`}>
              <CustomerView />
            </Route>
            <Route strict exact path={`${path}/:orgName/menucategory`}>
              <MenuCategoriesView />
            </Route>
            <Route path={`${path}/:orgName/menucategory/:itemId?`}>
              <MenuCategoryView />
            </Route>
            <Route strict exact path={`${path}/:orgName/menuitem`}>
              <MenuItemsView />
            </Route>
            <Route strict path={`${path}/:orgName/menuitem/:itemId?`}>
              <MenuItemView />
            </Route>
            <Route strict exact path={`${path}/:orgName`}>
              <OrganizationView />
            </Route>
            <Route>
              <NotFoundView />
            </Route>
          </Switch>
        </ProvideOrganizationContext>
      </Route>
    </Switch>
  );
}