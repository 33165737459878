import React from 'react';
import { Button } from 'react-bootstrap';
import BaseCollection from './BaseCollection';

interface IDeleteButton {
  onClick: () => void;
  collection?: BaseCollection;
  itemId?: string;
  size?: "sm" | "lg" | undefined;
}
export default function DeleteButton(options: IDeleteButton): JSX.Element {
  const onClickFn = async () => {
    if (options.collection !== undefined && options.itemId !== undefined) {
      await options.collection.deleteAsync(options.itemId);
    }
    options.onClick();
  };
  return (
    <Button
      variant="danger"
      aria-label="Delete"
      onClick={onClickFn}
      size={options.size}>
      X
    </Button>
  );
}
