import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotFoundView from '../../../Initializer/NotFoundView';
import BaseCollection from "../../../Utils/BaseCollection";
import BaseModel from "../../../Utils/BaseModel";
import { FormBuilder } from '../../../Utils/FormBuilder';
import { DropDownValueType } from '../../../Utils/FormDropDown';
import { StringValueType } from '../../../Utils/FormText';

import { useOrganizationContext } from '../ProvideOrganizationContext';
import { MemberModel, MembersModel, MemberRoles } from './MembersModel';

async function initializeModel(collection: BaseCollection, itemId: string) {
  const item = await collection.getByIdAsync(itemId);
  if (item === null) {
    console.log("item not found: " + itemId);
    return null;
  }
  return item;
}

interface IEditParams {
  itemId?: string;
}

interface IItemView {
  model: MemberModel;
  collection: BaseCollection;
}
function ItemView({ model, collection }: IItemView): JSX.Element {
  const history = useHistory();
  const onDone = () => {
    history.push("../member");
  };
  
  const modelId = model.id === null ? "" : model.id;
  
  const values = [];
  values.push(new StringValueType(
    "Email",
    "email",
    useState(modelId),
    modelId !== "" ? null : (model: BaseModel, value: string) => (model as MemberModel).id = value));
  values.push(new DropDownValueType(
    "Role", 
    "role", 
    useState(model.role),
    [
      { id: MemberRoles.Admin, name: MemberRoles.Admin },
      { id: MemberRoles.User, name: MemberRoles.User },
    ],
    (model: BaseModel, value: string) => (model as MemberModel).role = value));
  console.log(model);
  return <FormBuilder
    name="Member"
    friendlyName={model.id}
    values={values}
    onDone={onDone}
    model={model}
    collection={collection} />;
}

export default function MemberView(): JSX.Element {
  const [model, setModel] = useState<MemberModel>(new MemberModel("", MemberRoles.Admin));
  
  const organizationContext = useOrganizationContext();
  if (organizationContext.organization === null || organizationContext.organization.id === null) {
    throw "unreachable";
  }
  const organizationId = organizationContext.organization.id;
  const [collection] = useState(new MembersModel(organizationId));
  const history = useHistory();
  const params = useParams<IEditParams>();
  const itemId = params.itemId;
  const isAdmin = organizationContext.member?.role === MemberRoles.Admin;

  useEffect(() => {
    const asyncEffect = async () => {
      if (itemId !== undefined) {
        const model = await initializeModel(collection, itemId);
        if (model === null) {
          console.error("member not found");
          history.replace("/notFound");
          return;
        }
        setModel(model as MemberModel);
      }
    };
    asyncEffect();
  }, [organizationId, history, itemId, setModel, collection]);

  if (!isAdmin) {
    return <NotFoundView />;
  }

  if (itemId !== undefined && model.id === "") {
    return <div />;
  } else {
    return <ItemView
      model={model}
      collection={collection} />;
  }
}
