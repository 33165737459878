import React from 'react';
import { Alert, Container } from 'react-bootstrap';

export function OfflineBanner(): JSX.Element {
  return (
    <Container>
      <Alert variant="warning">
        <h2>Can&apos;t Connect</h2>
        We&apos;re having issues connecting right now. Please try again.
      </Alert>
    </Container>
  );
}