import React, { useState, useEffect } from "react";

import { MenuCategoryModel, MenuCategoriesModel } from "./MenuCategoriesModel";
import { useOrganizationContext } from "../../ProvideOrganizationContext";
import { Link, useRouteMatch } from "react-router-dom";
import { HelmetOrgTitle } from "../../../../Utils/HelmetTitle";

function MenuCategory({ model }: { model: MenuCategoryModel }) {
  const { url } = useRouteMatch();

  return (
    <tr>
      <td>{model.id}</td>
      <td><Link to={url + "/" + model.id}>{model.name}</Link></td>
    </tr>
  );
}

function MenuCategoryHeader() {
  return (
    <tr>
      <td>Id</td>
      <td>Name</td>
    </tr>
  );
}

function MenuCategoryList({ items }: { items: MenuCategoryModel[] | null }) {
  if (items === null) {
    return null;
  } else if (items.length === 0) {
    return (
      <div>
        No items
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          <MenuCategoryHeader />
        </thead>
        <tbody>
          { items.map(i => <MenuCategory model={i} key={i.id} />) }
        </tbody>
      </table>
    );
  }
}

export default function MenuCategoriesView(): JSX.Element {
  const [items, setItems] = useState<MenuCategoryModel[] | null>(null);
  const organizationContext = useOrganizationContext();
  const { url } = useRouteMatch();

  if (organizationContext.organization === null || organizationContext.organization.id === null) {
    throw "organization missing";
  }

  useEffect(() => {
    async function asyncEffect() {
      if (organizationContext.organization === null || organizationContext.organization.id === null) {
        throw "organization missing";
      }

      const itemsModel = new MenuCategoriesModel(organizationContext.organization.id);
      const items = await itemsModel.getAllAsync();
      console.log(items);
      setItems(items as MenuCategoryModel[]);
    }
    asyncEffect();
  }, [organizationContext.organization]);

  const addUrl = url + "/";
  console.log(items);
  return (
    <div>
      <HelmetOrgTitle title="Menu Categories" />
      <h1>Menu Categories</h1>
      <Link to={addUrl}>Add</Link>
      <MenuCategoryList items={items} />
    </div>
  );
}