import { OrganizationsModel } from "./OrganizationsModel";

export default class OrganizationUtils {
  #organizationsModel: OrganizationsModel;

  constructor() {
    this.#organizationsModel = new OrganizationsModel();
  }

  validateName(name: string): boolean | string {
    const regex = /^[a-zA-Z ']*$/g;
    const minLength = 3;
    const maxLength = 15;

    if (!regex.test(name)) {
      return "regex";
    } else if (name.length < minLength) {
      return "minLength";
    } else if (name.length > maxLength) {
      return "maxLength";
    }

    return true;
  }

  cleanName(friendlyName: string): string {
    let name = friendlyName.replace(/[^a-zA-Z -]/g, "");
    name = name.trim();
    name = name.replace(/[ ]/g, "-");
    name = name.toLowerCase();
    return name;
  }

  async validateUniqueAsync(friendlyName: string): Promise<boolean | string> {
    const name = this.cleanName(friendlyName);
    const results = await this.#organizationsModel.getByNameAsync(name);
    const result = (results === null) ? true : "notUnique";
    return result;
  }
}