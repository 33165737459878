import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { HelmetTitle } from '../../Utils/HelmetTitle';

import { useOrganizationContext } from './ProvideOrganizationContext';

export default function OrganizationView(): JSX.Element {
  const organizationContext = useOrganizationContext();
  const { url } = useRouteMatch();
  if (organizationContext.organization === null) {
    console.log("no org");
    return <div />;
  }

  return (
    <div>
      <HelmetTitle title={organizationContext.organization.friendlyName} />
      <h1>{organizationContext.organization.friendlyName}</h1>
      <div>
        <Link to={url + "/customer"}>Customers</Link>
      </div>
      <div>
        <Link to={url + "/member"}>Members</Link>
      </div>
      <div>
        <Link to={url + "/menuitem"}>Menu Items</Link>
      </div>
      <div>
        <Link to={url + "/menucategory"}>Menu Categories</Link>
      </div>
    </div>
  );
}