import firebase from "firebase/app";
import BaseCollection from "../../../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../../../Utils/BaseModel";
import FirestorePath from "../../../../Utils/FirestorePath";

export class MenuItemModel extends BaseModel {
  name: string;
  description: string;
  categories: string[];
  modifiers: string[];
  images: string[];

  constructor(
    id: string | null,
    name: string,
    description: string,
    categories: string[],
    modifiers: string[],
    images: string[]) {
    super(id);
    this.name = name;
    this.description = description;
    this.categories = categories;
    this.modifiers = modifiers;
    this.images = images;
  }
}

class MenuItemConverter implements IDocumentConverter {
  toFirestore(menuItem: MenuItemModel) {
    return {
      id: menuItem.id,
      name: menuItem.name,
      description: menuItem.description,
      categories: menuItem.categories,
      modifiers: menuItem.modifiers,
      images: menuItem.images,
    };
  }

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): MenuItemModel {
    const data = snapshot.data();
    return new MenuItemModel(
      snapshot.id,
      data?.name,
      data?.description,
      data?.categories,
      data?.modifiers,
      data?.images);
  }
}

export class MenuItemsModel extends BaseCollection {
  constructor(organizationId: string) {
    super(FirestorePath.buildMenuItemsPath(organizationId), new MenuItemConverter());
  }
}