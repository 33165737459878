import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BaseCollection from "../../../../Utils/BaseCollection";
import BaseModel from "../../../../Utils/BaseModel";
import { FormBuilder } from '../../../../Utils/FormBuilder';
import { IFormDropdownItem } from '../../../../Utils/FormDropDown';
import { DropDownArrayValueType } from '../../../../Utils/FormDropDownList';
import { ImageInputValueType } from '../../../../Utils/FormImageInput';
import { StringValueType } from '../../../../Utils/FormText';
import { StringArrayValueType } from '../../../../Utils/FormTextList';

import { useOrganizationContext } from '../../ProvideOrganizationContext';
import { MenuCategoriesModel, MenuCategoryModel } from '../MenuCategory/MenuCategoriesModel';
import { MenuItemModel, MenuItemsModel } from './MenuItemsModel';

async function initializeModel(collection: BaseCollection, itemId: string) {
  const item = await collection.getByIdAsync(itemId);
  if (item === null) {
    console.log("item not found: " + itemId);
    return null;
  }
  return item;
}

async function initializeMenuCategoriesAsync(organizationId: string) {
  const menuCategoriesModel = new MenuCategoriesModel(organizationId);
  const menuCategories = await menuCategoriesModel.getAllAsync() as MenuCategoryModel[];
  const dropDownValues = Array.from(menuCategories, (item): IFormDropdownItem => {
    if (item.id === null) {
      throw "unreachable";
    }
    return {
      id: item.id,
      name: item.name
    };
  });
  return dropDownValues;
}

interface IEditParams {
  itemId?: string;
}

interface IItemView {
  model: MenuItemModel;
  menuCategories: IFormDropdownItem[];
  collection: BaseCollection;
}
function ItemView({ model, menuCategories, collection }: IItemView): JSX.Element {
  const history = useHistory();
  const onDone = () => {
    history.push("../menuitem");
  };
  
  const values = [];
  values.push(new StringValueType(
    "Name",
    "name",
    useState(model.name),
    (model: BaseModel, value: string) => (model as MenuItemModel).name = value));
  values.push(new StringValueType(
    "Description", 
    "description", 
    useState(model.description),
    (model: BaseModel, value: string) => (model as MenuItemModel).description = value));
  values.push(new ImageInputValueType(
    "Images",
    "images",
    useState(model.images),
    (model: BaseModel, value: string[]) => (model as MenuItemModel).images = value));
  values.push(new DropDownArrayValueType(
    "Categories", 
    "categories", 
    useState(model.categories),
    menuCategories, 
    (model: BaseModel, value: string[]) => (model as MenuItemModel).categories = value));
  values.push(new StringArrayValueType(
    "Modifiers", 
    "modifiers", 
    useState(model.modifiers),
    (model: BaseModel, value: string[]) => (model as MenuItemModel).modifiers = value));

  return <FormBuilder
    name="Menu Item"
    friendlyName={model.name}
    values={values}
    onDone={onDone}
    model={model}
    collection={collection} />;
}

export default function MenuItemView(): JSX.Element {
  const [menuCategories, setMenuCategories] = useState<IFormDropdownItem[] | null>(null);
  const [model, setModel] = useState<MenuItemModel>(new MenuItemModel(null, "", "", [], [], []));
  
  const organizationContext = useOrganizationContext();
  if (organizationContext.organization === null || organizationContext.organization.id === null) {
    throw "unreachable";
  }
  const organizationId = organizationContext.organization.id;
  const [collection] = useState(new MenuItemsModel(organizationId));
  const history = useHistory();
  const params = useParams<IEditParams>();
  const itemId = params.itemId;

  useEffect(() => {
    const asyncEffect = async () => {
      if (itemId !== undefined) {
        const model = await initializeModel(collection, itemId);
        if (model === null) {
          console.error("menu item not found");
          history.replace("/notFound");
          return;
        }
        setModel(model as MenuItemModel);
      }
      const dropDownValues = await initializeMenuCategoriesAsync(organizationId);
      setMenuCategories(dropDownValues);
    };
    asyncEffect();
  }, [organizationId, history, itemId, setModel, collection]);

  if (menuCategories === null) {
    return <div />;
  } else {
    return <ItemView
      model={model}
      collection={collection}
      menuCategories={menuCategories} />;
  }
}
