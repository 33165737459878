import React, { Dispatch, SetStateAction } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import BaseModel from "./BaseModel";
import { BaseValueType, IValueType } from "./FormBuilder";
import { TextInput } from "./FormText";

interface IFormInput {
  item: string,
  onChange: (value: string) => void,
  onRemove: () => void
}
function FormInput(value: IFormInput) {
  return (
    <InputGroup>
      <TextInput
        value={value.item}
        onChange={value.onChange} />
      <InputGroup.Append>
        <Button variant="danger" onClick={value.onRemove}>X</Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

interface IFormTextList {
  id: string,
  displayName: string,
  value: string[],
  onChange: (value: string[]) => void,
}
export default function FormTextList({ id, displayName, value, onChange }: IFormTextList): JSX.Element {
  const onChangeFn = (key: number, newValue: string) => {
    const listCopy = [...value];
    listCopy[key] = newValue;
    console.log(listCopy);
    onChange(listCopy);
  };

  const addFn = () => {
    const listCopy = [...value];
    listCopy.push("");
    onChange(listCopy);
  };

  const removeFn = (key: number) => {
    const listCopy = [...value];
    listCopy.splice(key, 1);
    onChange(listCopy);
  };

  return (
    <Form.Group controlId={id}>
      <Form.Label>
        {displayName}
        <Button onClick={addFn}>Add</Button>
      </Form.Label>
      { value.map((item, i) => <FormInput 
        key={i}
        item={item} 
        onChange={(value: string) => onChangeFn(i, value)}
        onRemove={() => removeFn(i)} />) }
    </Form.Group>
  );
}

export class StringArrayValueType extends BaseValueType implements IValueType {
  #value: string[];
  #onFinalize: (model: BaseModel, value: string[]) => void;
  #valueSetter: Dispatch<SetStateAction<string[]>>;

  constructor(
    displayName: string,
    id: string,
    [value, valueSetter]: [string[], Dispatch<SetStateAction<string[]>>],
    onFinalize: (model: BaseModel, value: string[]) => void) {
    super(displayName, id);
    this.#value = value;
    this.#onFinalize = onFinalize;
    this.#valueSetter = valueSetter;
  }

  finalize(model: BaseModel): void {
    this.#onFinalize(model, this.#value);
  }

  getElement(key: string): JSX.Element {
    return (
      <FormTextList
        key={key}
        id={this.id}
        displayName={this.displayName}
        value={this.#value}
        onChange={(value: string[]) => this.#valueSetter(value)} />
    );
  }
}