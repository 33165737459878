import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BaseCollection from "../../../Utils/BaseCollection";
import BaseModel from "../../../Utils/BaseModel";
import { FormBuilder } from '../../../Utils/FormBuilder';
import { StringValueType } from '../../../Utils/FormText';

import { useOrganizationContext } from '../ProvideOrganizationContext';
import { CustomerModel, CustomersModel } from './CustomersModel';

async function initializeModel(collection: BaseCollection, itemId: string) {
  const item = await collection.getByIdAsync(itemId);
  if (item === null) {
    console.log("item not found: " + itemId);
    return null;
  }
  return item;
}

interface IEditParams {
  itemId?: string;
}

interface IItemView {
  model: CustomerModel;
  collection: BaseCollection;
}
function ItemView({ model, collection }: IItemView): JSX.Element {
  const history = useHistory();
  const onDone = () => {
    history.push("../customer");
  };
  
  const values = [];
  values.push(new StringValueType(
    "First Name",
    "firstName",
    useState(model.firstName),
    (model: BaseModel, value: string) => (model as CustomerModel).firstName = value));
  values.push(new StringValueType(
    "Last Name", 
    "lastName", 
    useState(model.lastName),
    (model: BaseModel, value: string) => (model as CustomerModel).lastName = value));
  values.push(new StringValueType(
    "Room Number", 
    "roomNumber", 
    useState(model.roomNumber),
    (model: BaseModel, value: string) => (model as CustomerModel).roomNumber = value));
  values.push(new StringValueType(
    "Type", 
    "type", 
    useState(model.type),
    (model: BaseModel, value: string) => (model as CustomerModel).type = value));
  return <FormBuilder
    name="Customer"
    friendlyName={`${model.firstName} ${model.lastName}`}
    values={values}
    onDone={onDone}
    model={model}
    collection={collection} />;
}

export default function CustomerView(): JSX.Element {
  const [model, setModel] = useState<CustomerModel>(new CustomerModel(null, "", "", "", ""));  
  
  const organizationContext = useOrganizationContext();
  if (organizationContext.organization === null || organizationContext.organization.id === null) {
    throw "unreachable";
  }
  const organizationId = organizationContext.organization.id;
  const [collection] = useState(new CustomersModel(organizationId));
  const history = useHistory();
  const params = useParams<IEditParams>();
  const itemId = params.itemId;

  useEffect(() => {
    const asyncEffect = async () => {
      if (itemId !== undefined) {
        const model = await initializeModel(collection, itemId);
        if (model === null) {
          console.error("customer not found");
          history.replace("/notFound");
          return;
        }
        setModel(model as CustomerModel);
      }
    };
    asyncEffect();
  }, [organizationId, history, itemId, setModel, collection]);

  if (itemId !== undefined && model.id === null) {
    return <div />;
  } else {
    console.log(model);
    return <ItemView
      model={model}
      collection={collection} />;
  }
}
