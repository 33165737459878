import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from 'react-router-dom';

import { CustomersModel, CustomerModel } from "./CustomersModel";
import { useOrganizationContext } from "../ProvideOrganizationContext";
import { HelmetOrgTitle } from "../../../Utils/HelmetTitle";

function CustomerItem({ model }: { model: CustomerModel }) {
  const { url } = useRouteMatch();
  return (
    <tr>
      <td><Link to={url + "/" + model.id}>{model.id}</Link></td>
      <td>{model.firstName}</td>
      <td>{model.lastName}</td>
      <td>{model.type}</td>
      <td>{model.roomNumber}</td>
    </tr>
  );
}

function CustomersList({ loaded, items }: { loaded: boolean, items: CustomerModel[]}) {
  if (!loaded) {
    return null;
  } else if (items.length === 0) {
    return (
      <div>
        No customers
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          <tr>
            <td>Id</td>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Type</td>
            <td>Room Number</td>
          </tr>
        </thead>
        <tbody>
          { items.map(i => <CustomerItem model={i} key={i.id} />) }
        </tbody>
      </table>
    );
  }
}

export default function CustomersView(): JSX.Element {
  const [items, setItems] = useState<CustomerModel[]>([]);
  const [loaded, setLoaded] = useState(false);
  const organizationContext = useOrganizationContext();
  const { url } = useRouteMatch();

  useEffect(() => {
    async function asyncEffect() {
      if (organizationContext.organization === null || organizationContext.organization.id === null) {
        throw "organization missing";
      }
      const customersModel = new CustomersModel(organizationContext.organization.id);
      const customers = (await customersModel.getAllAsync()) as CustomerModel[];
      console.log(customers);
      setItems(customers);
      setLoaded(true);
    }
    asyncEffect();
  }, [organizationContext.organization]);

  const addCustomerUrl = url + "/";
  console.log(items);
  return (
    <div>
      <HelmetOrgTitle title="Customers" />
      <h1>Customers</h1>
      <Link to={addCustomerUrl}>Add Customer</Link>
      <CustomersList items={items} loaded={loaded} />
    </div>
  );
}
