export default class FirestorePath {
  static TopLevelPath = "/";
  static OrganizationsName = "organizations";
  static MembersName = "members";

  static UsersPath = `${FirestorePath.TopLevelPath}users`;
  static OrganizationsPath = `${FirestorePath.TopLevelPath}${FirestorePath.OrganizationsName}`;

  static buildMenuItemsPath(organizationId: string): string {
    return `${FirestorePath.OrganizationsPath}/${organizationId}/menuitems/`;
  }

  static buildMenuCategoriesPath(organizationId: string): string {
    return `${FirestorePath.OrganizationsPath}/${organizationId}/menucategories/`;
  }

  static buildMembersPath(organizationId: string): string {
    return `${FirestorePath.OrganizationsPath}/${organizationId}/${FirestorePath.MembersName}/`;
  }

  static buildCustomersPath(organizationId: string): string {
    return `${FirestorePath.OrganizationsPath}/${organizationId}/customers/`;
  }
}
