import React, { Dispatch, SetStateAction } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import BaseModel from "./BaseModel";
import { BaseValueType, IValueType } from "./FormBuilder";
import { DropDownInput, IFormDropdownItem } from "./FormDropDown";

interface IFormInput {
  id?: string,
  value?: string,
  onChange?: (value: string) => void,
  dropDown: IFormDropdownItem[],
  onRemove: () => void,
}
function FormInput(options: IFormInput) {
  return (
    <InputGroup>
      <DropDownInput
        value={options.value}
        onChange={options.onChange}
        dropDown={options.dropDown} />
      <InputGroup.Append>
        <Button variant="danger" onClick={options.onRemove}>X</Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

interface IFormDropDownList {
  id: string,
  displayName: string,
  value: string[],
  onChange: (value: string[]) => void,
  dropDown: IFormDropdownItem[],
}
export default function FormDropDownList(options: IFormDropDownList): JSX.Element {
  const onChangeFn = (key: number, newValue: string) => {
    const listCopy = [...options.value];
    listCopy[key] = newValue;
    console.log(listCopy);
    options.onChange(listCopy);
  };

  const addFn = () => {
    const listCopy = [...options.value];

    if (options.dropDown.length > 0) {
      listCopy.push(options.dropDown[0].id);
    } else {
      listCopy.push("");
    }
    options.onChange(listCopy);
  };

  const removeFn = (key: number) => {
    const listCopy = [...options.value];
    listCopy.splice(key, 1);
    options.onChange(listCopy);
  };

  return (
    <Form.Group controlId={options.id}>
      <Form.Label>
        {options.displayName}
        <Button onClick={addFn}>Add</Button>
      </Form.Label>
      { options.value.map((item, i) => <FormInput 
        key={i}
        value={item} 
        onChange={(value: string) => onChangeFn(i, value)}
        onRemove={() => removeFn(i)}
        dropDown={options.dropDown} />) }
    </Form.Group>
  );
}

export class DropDownArrayValueType extends BaseValueType implements IValueType {
  #value: string[];
  #dropDownItems: IFormDropdownItem[];
  #onFinalize: (model: BaseModel, value: string[]) => void;
  #valueSetter: Dispatch<SetStateAction<string[]>>;

  constructor(
    displayName: string,
    id: string,
    [value, valueSetter]: [string[], Dispatch<SetStateAction<string[]>>],
    dropDownItems: IFormDropdownItem[],
    onFinalize: (model: BaseModel, value: string[]) => void) {
    super(displayName, id);
    this.#value = value;
    this.#dropDownItems = dropDownItems;
    this.#onFinalize = onFinalize;
    this.#valueSetter = valueSetter;
  }

  finalize(model: BaseModel): void {
    this.#onFinalize(model, this.#value);
  }

  getElement(key: string): JSX.Element {
    return (
      <FormDropDownList
        key={key}
        id={this.id}
        displayName={this.displayName}
        value={this.#value}
        dropDown={this.#dropDownItems}
        onChange={(value: string[]) => this.#valueSetter(value)} />
    );
  }
}