import React, { useState, useEffect, useContext, createContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { MenuItemModel, MenuItemsModel } from "./MenuItemsModel";
import { useOrganizationContext } from "../../ProvideOrganizationContext";
import { MenuCategoriesModel, MenuCategoryModel } from "../MenuCategory/MenuCategoriesModel";
import { Button } from "react-bootstrap";
import { HelmetOrgTitle } from "../../../../Utils/HelmetTitle";

const CategoryContext = createContext<MenuCategoryModel[] | undefined>(undefined);

function CategoryButton({ item }: { item: string }) {
  const categories = useContext(CategoryContext);
  const value = categories?.find(i => i.id === item)?.name;
  return <Button disabled={true}>{value}</Button>;
}

function MenuItem({ model }: { model: MenuItemModel }) {
  const { url } = useRouteMatch();
  return (
    <tr>
      <td><Link to={url + "/" + model.id}>{model.id}</Link></td>
      <td>{model.name}</td>
      <td>{model.description}</td>
      <td>{model.categories.map((item, i) => <CategoryButton key={"categories" + i} item={item}></CategoryButton>)}</td>
      <td>{model.modifiers.map((item, i) => <Button disabled={true} key={"modifiers" + i}>{item}</Button>)}</td>
    </tr>
  );
}

function MenuItemHeader() {
  return (
    <tr>
      <td>Id</td>
      <td>Name</td>
      <td>Description</td>
      <td>Categories</td>
      <td>Modifiers</td>
    </tr>
  );
}

function MenuItemList({ items }: { items: MenuItemModel[] | undefined }) {
  if (items === undefined) {
    return null;
  } else if (items.length === 0) {
    return (
      <div>
        No items
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          <MenuItemHeader />
        </thead>
        <tbody>
          { items.map(i => <MenuItem model={i} key={i.id} />) }
        </tbody>
      </table>
    );
  }
}

interface MenuItemListModel {
  items: MenuItemModel[],
  categories: MenuCategoryModel[]
}

export default function MenuItemsView(): JSX.Element {
  const [items, setItems] = useState<MenuItemListModel | null>(null);
  const organizationContext = useOrganizationContext();
  const { url } = useRouteMatch();
  if (organizationContext.organization === null || organizationContext.organization.id === null) {
    throw "organization missing";
  }

  useEffect(() => {
    async function asyncEffect() {
      if (organizationContext.organization === null || organizationContext.organization.id === null) {
        throw "organization missing";
      }

      const categoriesModel = new MenuCategoriesModel(organizationContext.organization.id);
      const categories = await categoriesModel.getAllAsync();
      const menuItemsModel = new MenuItemsModel(organizationContext.organization.id);
      const menuItems = await menuItemsModel.getAllAsync();
      console.log(menuItems);
      setItems({
        items: menuItems as MenuItemModel[],
        categories: categories as MenuCategoryModel[]
      });
    }
    asyncEffect();
  }, [organizationContext.organization]);

  const addUrl = url + "/";
  console.log(items);
  return (
    <div>
      <HelmetOrgTitle title="Menu Items" />
      <h1>Menu Items</h1>
      <Link to={addUrl}>Add</Link>
      <CategoryContext.Provider value={items?.categories}>
        <MenuItemList items={items?.items} />
      </CategoryContext.Provider>
    </div>
  );
}