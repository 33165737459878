import React, { Fragment } from "react";
import firebase from "firebase/app";
import { Button, Col, Row } from "react-bootstrap";
import { HelmetTitle } from "../../Utils/HelmetTitle";

// eslint-disable-next-line max-len
// Reference file: https://github.com/firebase/quickstart-js/blob/c16bc05c8096d76d8de793c52fb1895604c1f477/auth/microsoft-popup.html
async function MicrosoftAuth() {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.addScope('User.read');
  provider.setCustomParameters({
    // Force re-consent.
    prompt: 'consent',
  });
  
  try {
    const userCredential = await firebase.auth().signInWithPopup(provider);
    console.log(userCredential);
  } catch (error) {
    console.error({
      message: error.message,
      code: error.code,
      email: error.email,
      credential: error.credential,
      accountExistWithDiffCred: (error.code === 'auth/account-exists-with-different-credential')
    });
    console.log(error);
  }
}

export default function SignIn(): JSX.Element {
  return (
    <Fragment>
      <HelmetTitle title="Log in" />
      <Row>
        <Col xs={{ span: 12 }} className="center-align">
          <Button onClick={MicrosoftAuth}>Sign In with Microsoft</Button>
        </Col>
      </Row>
    </Fragment>
  );
}