import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import BaseModel from './BaseModel';
import { BaseValueType, IValueType } from './FormBuilder';

export interface IFormDropdownItem {
  id: string,
  name: string
}

function ensureValueInDropDown(value: string | undefined, dropDown: IFormDropdownItem[]) {
  if (dropDown.length > 0 && (value === undefined || dropDown.find(obj => obj.id === value) === undefined)) {
    throw "invalid value";
  }
}

interface IDropDownInput {
  value?: string,
  onChange?: (value: string) => void,
  dropDown: IFormDropdownItem[],
  disabled?: boolean
}
export function DropDownInput(options: IDropDownInput): JSX.Element {
  ensureValueInDropDown(options.value, options.dropDown);

  let inputField: JSX.Element;
  if (options.dropDown.length > 0) {
    const onChangeFn = (e: React.FormEvent<HTMLSelectElement>) => {
      ensureValueInDropDown(e.currentTarget.value, options.dropDown);

      if (options.onChange !== undefined 
        && (options.disabled === undefined || options.disabled === false)
        && (options.dropDown.length > 0)) {
        options.onChange(e.currentTarget.value);
      }
    };

    inputField = (
      <Form.Control
        as="select"
        defaultValue={options.value} 
        onBlur={onChangeFn}>
        {options.dropDown.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
      </Form.Control>
    );
  } else {
    inputField = (
      <Form.Control
        as="select"
        value={options.value}
        disabled={true}>
        <option key={0}>-- No items --</option>
      </Form.Control>
    );
  }

  return inputField;
}

interface IFormDropDown {
  id: string,
  displayName: string,
  onChange?: (value: string) => void,
  value?: string,
  disabled?: boolean,
  dropDown: IFormDropdownItem[]
}
export default function FormDropDown(options: IFormDropDown): JSX.Element {
  return (
    <Form.Group controlId={options.id}>
      <Form.Label>{options.displayName}</Form.Label>
      <DropDownInput
        value={options.value}
        disabled={options.disabled}
        onChange={options.onChange}
        dropDown={options.dropDown} />
    </Form.Group>
  );
}

export class DropDownValueType extends BaseValueType implements IValueType {
  #value: string;
  #onFinalize: (model: BaseModel, value: string) => void;
  #valueSetter: Dispatch<SetStateAction<string>>;
  #dropDown: IFormDropdownItem[];

  constructor(
    displayName: string,
    id: string,
    [value, valueSetter]: [string, Dispatch<SetStateAction<string>>],
    dropDown: IFormDropdownItem[],
    onFinalize: (model: BaseModel, value: string) => void) {
    super(displayName, id);
    this.#value = value;
    this.#onFinalize = onFinalize;
    this.#valueSetter = valueSetter;
    this.#dropDown = dropDown;

    ensureValueInDropDown(value, dropDown);
  }

  finalize(model: BaseModel): void {
    ensureValueInDropDown(this.#value, this.#dropDown);
    this.#onFinalize(model, this.#value);
  }

  getElement(key: string): JSX.Element {
    return (
      <FormDropDown
        key={key}
        displayName={this.displayName}
        id={this.id}
        value={this.#value}
        onChange={(value: string) => this.#valueSetter(value)}
        dropDown={this.#dropDown} />
    );
  }
}