import React, { useState, useEffect } from "react";

import { MemberModel, MembersModel, MemberRoles } from "./MembersModel";
import { useOrganizationContext } from "../ProvideOrganizationContext";
import { UserModel, UsersModel } from "../../Auth/UsersModel";
import { Link, useRouteMatch } from "react-router-dom";
import { HelmetOrgTitle } from "../../../Utils/HelmetTitle";

function MemberItem({ model }: { model: MemberModel }) {
  const [name, setName] = useState("");
  const { url } = useRouteMatch();
  const isAdmin = useOrganizationContext().member?.role === MemberRoles.Admin;

  useEffect(() => {
    async function asyncEffect() {
      if (model.id === null) {
        throw "missing id";
      }

      const usersModel = new UsersModel();
      const user = (await usersModel.getUserByEmailAsync(model.id)) as UserModel;
      if (user !== null) {
        setName(user.name);
      }
    }
    asyncEffect();
  }, [model]);

  const modelIdCellValue = isAdmin
    ? <Link to={url + "/" + model.id}>{model.id}</Link>
    : model.id;

  return (
    <tr>
      <td>{name}</td>
      <td>{modelIdCellValue}</td>
      <td>{model.role}</td>
    </tr>
  );
}

function MembersList({ items }: { items: MemberModel[] | null }) {
  if (items === null) {
    return null;
  } else if (items.length === 0) {
    return (
      <div>
        No members
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Email</td>
            <td>Role</td>
          </tr>
        </thead>
        <tbody>
          { items.map(i => <MemberItem model={i} key={i.id} />) }
        </tbody>
      </table>
    );
  }
}

export default function MembersView(): JSX.Element {
  const [items, setItems] = useState<MemberModel[] | null>(null);
  const { url } = useRouteMatch();
  const organizationContext = useOrganizationContext();
  if (organizationContext.organization === null) {
    throw "organization missing";
  }

  useEffect(() => {
    async function asyncEffect() {
      if (organizationContext.organization === null || organizationContext.organization.id === null) {
        throw "organization missing";
      }

      const membersModel = new MembersModel(organizationContext.organization.id);
      const members = await membersModel.getAllAsync() as MemberModel[];
      console.log(members);
      setItems(members);
    }
    asyncEffect();
  }, [organizationContext.organization]);

  const isAdmin = useOrganizationContext().member?.role === MemberRoles.Admin;
  const addLink = isAdmin ? <Link to={url + "/"}>Add</Link> : <div />;
  return (
    <div>
      <HelmetOrgTitle title="Members" />
      <h1>Members</h1>
      {addLink}
      <MembersList items={items} />
    </div>
  );
}