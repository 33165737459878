import firebase from "firebase/app";

import BaseCollection from "../../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../../Utils/BaseModel";
import FirestorePath from "../../../Utils/FirestorePath";

export class MemberModel extends BaseModel {
  role: string;

  constructor(id: string, role: string) {
    super(id);
    this.role = role;
  }
}

class MemberConverter implements IDocumentConverter {
  toFirestore(member: MemberModel) {
    return {
      id: member.id,
      role: member.role,
    };
  }

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = snapshot.data();
    return new MemberModel(snapshot.id, data?.role);
  }
}

export enum MemberRoles {
  Admin = "admin",
  User = "user",
}

export class MembersModel extends BaseCollection {
  constructor(organizationId: string) {
    super(FirestorePath.buildMembersPath(organizationId), new MemberConverter());
  }
}