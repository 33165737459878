import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import BaseModel from './BaseModel';
import { CloudImageWithCaption } from './CloudImage';
import FirebaseStorage, { StorageMetadata } from '../Firebase/FirebaseStorage';
import { BaseValueType, IValueType } from './FormBuilder';

const typeMapping = new Map<string, string>([
  ["image/jpeg", "jpg"],
  ["image/bmp", "bmp"],
  ["image/png", "png"]
]);

interface IImageInput {
  onChange: (value: string[]) => void,
  value: string[],
}
export function ImageInput(options: IImageInput): JSX.Element {
  const onChange = async (formEvent: React.FormEvent<HTMLInputElement>) => {
    if (formEvent.currentTarget.files === null || formEvent.currentTarget.files.length === 0) {
      return;
    }
    const file = formEvent.currentTarget.files[0];
    formEvent.currentTarget.value = "";
    const type = file.type;
    const ext = typeMapping.get(type);
    if (ext === undefined) {
      console.error(`${type} not supported`);
      return;
    } else if (file.size > 500 * 1024) {
      console.error(`Size too large: ${file.size}`);
      return;
    }

    const metadata = new StorageMetadata();
    metadata.setName(file.name);
    const path = `images/${uuid()}.${ext}`;
    const storage = new FirebaseStorage();
    try {
      await storage.getRef(path).put(file, metadata);
    } catch (e) {
      console.error(e);
      return;
    }

    const listCopy = [...options.value];
    listCopy.push(path);
    options.onChange(listCopy);
  };
  const deleteItem = async (key: number) => {
    const listCopy = [...options.value];
    listCopy.splice(key, 1);
    options.onChange(listCopy);
  };
  return (
    <Fragment>
      <Form.File onChange={onChange} />
      <Form.Text muted>
        Allowed: jpg/bmp/png. Under 500 KB.
      </Form.Text>
      { options.value.map((item, i) => <CloudImageWithCaption key={i} src={item} onDelete={() => deleteItem(i)} />)}
    </Fragment>
  );
}

interface IFormImageInput {
  id: string,
  displayName: string,
  value: string[],
  onChange: (value: string[]) => void,
}
export default function FormImageInput(options: IFormImageInput): JSX.Element {
  return (
    <Form.Group controlId={options.id}>
      <Form.Label>
        {options.displayName}
      </Form.Label>
      <ImageInput
        onChange={options.onChange}
        value={options.value} />
    </Form.Group>
  );
}

export class ImageInputValueType extends BaseValueType implements IValueType {
  #value: string[];
  #onFinalize: (model: BaseModel, value: string[]) => void;
  #valueSetter: Dispatch<SetStateAction<string[]>>;

  constructor(
    displayName: string,
    id: string,
    [value, valueSetter]: [string[], Dispatch<SetStateAction<string[]>>],
    onFinalize: (model: BaseModel, value: string[]) => void) {
    super(displayName, id);
    this.#value = value;
    this.#onFinalize = onFinalize;
    this.#valueSetter = valueSetter;
  }

  finalize(model: BaseModel): void {
    this.#onFinalize(model, this.#value);
  }

  getElement(key: string): JSX.Element {
    return (
      <FormImageInput
        key={key}
        id={this.id}
        displayName={this.displayName}
        value={this.#value}
        onChange={(value: string[]) => this.#valueSetter(value)} />
    );
  }
}