import firebase from "firebase/app";

export default class BaseModel {
  id: string | null;

  constructor(id: string | null) {
    this.id = id;
  }
}

export interface IDocumentConverter {
  toFirestore(item: BaseModel) : firebase.firestore.DocumentData;
  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): BaseModel;
}