import firebase from "firebase/app";

import BaseCollection from "../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../Utils/BaseModel";
import FirestorePath from "../../Utils/FirestorePath";
import { UserModel } from "../Auth/UsersModel";

export class OrganizationModel extends BaseModel {
  name: string;
  friendlyName: string;

  // creator allows the themselves to become a member at time of creation
  creator: string | null = null;

  constructor(id: string | null, name: string, friendlyName: string) {
    super(id);
    this.name = name;
    this.friendlyName = friendlyName;
  }
}

class OrganizationConverter implements IDocumentConverter {
  toFirestore(organization: OrganizationModel) {
    return {
      name: organization.name,
      friendlyName: organization.friendlyName,
      creator: organization.creator,
    };
  }

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = snapshot.data();
    return new OrganizationModel(
      snapshot.id,
      data?.name,
      data?.friendlyName);
  }
}

export class OrganizationsModel extends BaseCollection {

  constructor() {
    super(FirestorePath.OrganizationsPath, new OrganizationConverter());
  }

  async getByNameAsync(name: string): Promise<OrganizationModel | null> {
    const querySnapshot = await this.collection.where("name", "==", name)
      .get();
    if (querySnapshot.size === 0) {
      return null;
    } else {
      const organizationConverter = new OrganizationConverter();
      return organizationConverter.fromFirestore(querySnapshot.docs[0]);
    }
  }

  async getOrganizationsByUserAsync(user: UserModel): Promise<OrganizationModel[]> {
    console.log(user);
    const querySnapshot = await this.db.collectionGroup(FirestorePath.MembersName)
      .where("id", "==", user.email)
      .get();

    const organizations = [];
    for (const i in querySnapshot.docs) {
      const doc = querySnapshot.docs[i];
      const parent = await doc.ref.parent.parent?.get();
      if (parent === undefined) {
        throw "parent not found";
      }
      const organization = new OrganizationConverter().fromFirestore(parent);
      organizations.push(organization);
    }

    return organizations;
  }
}