import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useOrganizationContext } from '../Modules/Organizations/ProvideOrganizationContext';

interface IHelmetEditTitle {
  isNew: boolean;
  name: string;
  friendlyName: string | null;
}
export function HelmetEditTitle(options: IHelmetEditTitle): JSX.Element {
  const titleStr = options.isNew ? `Add ${options.name}` : `Edit ${options.friendlyName}`;
  return <HelmetOrgTitle
    title={titleStr} />;
}

export function HelmetOrgTitle({ title }: { title: string }): JSX.Element {
  const org = useOrganizationContext();
  const titleStr = `${title} - ${org.organization?.friendlyName}`;
  return (
    <Helmet>
      <title>{titleStr}</title>
    </Helmet>
  );
}

export function HelmetTitle({ title }: { title: string }): JSX.Element {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}