import React, { useEffect, useState } from "react";
import { Figure, Image } from "react-bootstrap";
import DeleteButton from "./DeleteButton";
import FirebaseStorage from "../Firebase/FirebaseStorage";

interface IImageData {
  url?: string;
  name?: string;
}

async function getImage(path: string, setImageData: React.Dispatch<React.SetStateAction<IImageData>>) {
  const storage = new FirebaseStorage();
  const ref = storage.getRef(path);
  const url = await ref.getDownloadURL();
  const metadata = await ref.getMetadata();
  setImageData({
    url: url,
    name: metadata.getName()
  });
}

interface ICloudImage {
  src: string;
}
export function CloudImage(options: ICloudImage): JSX.Element {
  const [imageData, setImageData] = useState<IImageData>({
    url: undefined,
    name: undefined,
  });
  useEffect(() => {
    getImage(options.src, setImageData);
  }, [options.src, setImageData]);

  return (
    <Image
      src={imageData.url}
      rounded
      fluid />
  );
}

interface ICloudImageWithCaption {
  src: string;
  onDelete?: () => void;
}
export function CloudImageWithCaption(options: ICloudImageWithCaption): JSX.Element {
  const [imageData, setImageData] = useState<IImageData>({
    url: undefined,
    name: undefined,
  });
  useEffect(() => {
    getImage(options.src, setImageData);
  }, [options.src, setImageData]);

  const deleteButton = options.onDelete !== undefined
    ? <DeleteButton onClick={options.onDelete} size="sm" />
    : null;
  return (
    <Figure>
      <Figure.Image
        width="150"
        height="150"
        src={imageData.url}
        thumbnail
        fluid />
      <Figure.Caption>
        {imageData.name}
        <br />
        {deleteButton}
      </Figure.Caption>
    </Figure>
  );
}