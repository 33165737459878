import firebase from "firebase/app";

export function FirebaseInitializer(): void {
  if (!firebase.apps.length) {
    // Initialize Firebase
    firebase.initializeApp({
      apiKey: "AIzaSyCSrgPOUlUkUMZjplpzKBJzLt9oyVcn4kE",
      authDomain: "print-5b1b7.firebaseapp.com",
      projectId: "print-5b1b7",
      storageBucket: "print-5b1b7.appspot.com",
      messagingSenderId: "337469152677",
      appId: "1:337469152677:web:3afa52abeb3b6c63f49c4c",
      measurementId: "G-Q781B7816H"
    });

    if (window.location.hostname === "localhost") {
      firebase.firestore().useEmulator("localhost", 8080);
    }
  }
}

let GetAuth = (): firebase.auth.Auth => {
  const auth = firebase.auth();

  if (window.location.hostname === "localhost") {
    // auth.useEmulator("http://localhost:9099");
  }

  GetAuth = (): firebase.auth.Auth => auth;
  return auth;
};

export default GetAuth;