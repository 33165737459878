import React, { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import GetAuth from "../../Firebase/FirebaseConfig";

import { HelmetTitle } from "../../Utils/HelmetTitle";

export default function SignOutView(): JSX.Element {
  const signOutClick = () => {
    GetAuth().signOut();
  };

  return (
    <Fragment>
      <HelmetTitle title="Log out" />
      <Row>
        <Col xs={{ span: 12 }} className="center-align">
          <Button onClick={signOutClick}>Sign Out</Button>
        </Col>
      </Row>
    </Fragment>
  );
}