import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { HelmetTitle } from '../Utils/HelmetTitle';

export default function NotFoundView(): JSX.Element {
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <div>
      <HelmetTitle title="Not Found" />
      <h1>Not Found</h1>
      <Button onClick={goBack}>Go Back</Button>
    </div>
  );
}