import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

// https://firebase.google.com/docs/web/setup#available-libraries
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { FirebaseInitializer } from "./Firebase/FirebaseConfig";

import { ProvideAuth } from './Initializer/ProvideAuth';

import './App.css';
import AppNavbar from "./AppNavbar";
import MainContent from "./Initializer/MainContent";
import { OfflineBanner } from "./Initializer/OfflineBanner";
import { Offline, Online } from "react-detect-offline";

export default function App(): JSX.Element {
  FirebaseInitializer();

  return (
    <React.StrictMode>
      <HelmetProvider>
        <ProvideAuth>
          <Router>
            <Offline>
              <OfflineBanner />
            </Offline>
            <Online>
              <AppNavbar />
              <MainContent />
            </Online>
          </Router>
        </ProvideAuth>
      </HelmetProvider>
    </React.StrictMode>
  );
}
