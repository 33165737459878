import firebase from "firebase/app";

import BaseCollection from "../../../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../../../Utils/BaseModel";
import FirestorePath from "../../../../Utils/FirestorePath";

export class MenuCategoryModel extends BaseModel {
  name: string;

  constructor(
    id: string | null,
    name: string) {
    super(id);
    this.name = name;
  }
}

class MenuCategoryConverter implements IDocumentConverter {
  toFirestore(menuItem: MenuCategoryModel) {
    return {
      id: menuItem.id,
      name: menuItem.name,
    };
  }

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): MenuCategoryModel {
    const data = snapshot.data();
    return new MenuCategoryModel(
      snapshot.id,
      data?.name);
  }
}

export class MenuCategoriesModel extends BaseCollection {
  constructor(organizationId: string) {
    super(FirestorePath.buildMenuCategoriesPath(organizationId), new MenuCategoryConverter());
  }
}