import React from "react";
import { Container } from "react-bootstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import SignInView from "../Modules/Auth/SignInView";
import SignOutView from "../Modules/Auth/SignOutView";
import { UserModel } from "../Modules/Auth/UsersModel";
import OrganizationRoutes from "../Modules/Organizations/OrganizationRoutes";
import AuthRoute from "./AuthRoute";
import NotFoundView from "./NotFoundView";

export default function MainContent(): JSX.Element {
  const ensureLoggedOut = (user: UserModel | null) => user === null;
  const ensureLoggedIn = (user: UserModel | null) => user !== null;

  return (
    <Container>
      <Switch>
        <AuthRoute path="/login"
          checkFailRedirect="/org"
          authCheck={ensureLoggedOut}>
          <SignInView />
        </AuthRoute>
        <AuthRoute path="/logout"
          checkFailRedirect="/login"
          authCheck={ensureLoggedIn}>
          <SignOutView />
        </AuthRoute>
        <AuthRoute
          path="/org"
          authCheck={ensureLoggedIn}
          checkFailRedirect="/login">
          <OrganizationRoutes />
        </AuthRoute>                
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route>
          <NotFoundView />
        </Route>
      </Switch>
    </Container>
  );
}