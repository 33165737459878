import firebase from "firebase/app";

import BaseCollection from "../../../Utils/BaseCollection";
import BaseModel, { IDocumentConverter } from "../../../Utils/BaseModel";
import FirestorePath from "../../../Utils/FirestorePath";

export class CustomerModel extends BaseModel {
  firstName: string;
  lastName: string;
  type: string;
  roomNumber: string;

  constructor(id: string | null, firstName: string, lastName: string, type: string, roomNumber: string) {
    super(id);
    this.firstName = firstName;
    this.lastName = lastName;
    this.type = type;
    this.roomNumber = roomNumber;
  }
}

class CustomerConverter implements IDocumentConverter {
  toFirestore(customer: CustomerModel) {
    return {
      firstName: customer.firstName,
      lastName: customer.lastName,
      type: customer.type,
      roomNumber: customer.roomNumber,
    };
  }

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = snapshot.data();
    return new CustomerModel(
      snapshot.id, 
      data?.firstName,
      data?.lastName,
      data?.type,
      data?.roomNumber);
  }
}

export class CustomersModel extends BaseCollection {
  constructor(organizationId: string) {
    super(FirestorePath.buildCustomersPath(organizationId), new CustomerConverter());
  }
}